<script>
import Layout from "@/router/layouts/main";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
  },
  data() {
    return {
      status: {
        bling: 'inactive',
        olist: 'inactive',
      }
    };
  },
  methods: {
  },
  mounted() {
  }
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-md-flex justify-content-between mb-3">
      <div>
        <h3>Integrações</h3>
        <p></p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <div class="mb-4 d-flex justify-content-between">
              <img class="integration-logo" src="@/assets/images/integrations/bling.svg" />
              <div class="text-center">
                <span v-if="status.bling == 'inactive'" class="bg-danger rounded px-2 font-size-12 text-white">INATIVO</span>
                <span v-else-if="status.bling == 'active'" class="bg-success rounded px-2 font-size-12 text-white">ATIVO</span>
                <span v-else-if="status.bling == 'soon'" class="bg-dark rounded px-2 font-size-12 text-white">EM BREVE</span>
              </div>
            </div>
            <h5>Bling ERP</h5>
            <p>Sistema de gestão online, gere suas notas fiscais, integre com o SIGEP, controle de estoque e muito mais.</p>
            <button class="btn btn-default btn-block">Acessar</button>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <div class="mb-4 d-flex justify-content-between">
              <img class="integration-logo" src="@/assets/images/integrations/olist.svg" />
              <div class="text-center">
                <span v-if="status.olist == 'inactive'" class="bg-danger rounded px-2 font-size-12 text-white">INATIVO</span>
                <span v-else-if="status.olist == 'active'" class="bg-success rounded px-2 font-size-12 text-white">ATIVO</span>
                <span v-else-if="status.olist == 'soon'" class="bg-dark rounded px-2 font-size-12 text-white">EM BREVE</span>
              </div>
            </div>
            <h5>Olist Tiny</h5>
            <p>Emita notas fiscais (NFe) ilimitadas, gestão de pedidos, produtos e estoque em um só lugar.</p>
            <button class="btn btn-default btn-block">Acessar</button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.integration-logo {
  height: 40px;
}

.integration-logo.inactive {
  -webkit-filter: grayscale(90%);
  filter: grayscale(90%);
  opacity: 0.4;
}
</style>
